const Joi = require("@hapi/joi");

class Validator {
  constructor() {}

  validate(validations, data) {
    const keys = Object.keys(validations);
    let config = {};
    for (const key of keys) {
      config[key] = this.generateConfig(validations[key]);
    }

    const schema = Joi.object(config);
    const result = schema.validate(data);
    return result;
  }

  generateConfig(validationDescriptor) {
    let fieldConfig = this.generateTypeConfig(validationDescriptor);

    fieldConfig = this.generateRequiredConfig(
      validationDescriptor,
      fieldConfig
    );

    switch (validationDescriptor.type) {
      case "string":
        fieldConfig = this.generateStringConfig(
          validationDescriptor,
          fieldConfig
        );
        break;
      case "number":
        fieldConfig = this.generateNumberConfig(
          validationDescriptor,
          fieldConfig
        );
        break;
      case "date":
        fieldConfig = this.generateDateConfig(
          validationDescriptor,
          fieldConfig
        );
        break;
      case "boolean":
        fieldConfig = this.generateBooleanConfig(
          validationDescriptor,
          fieldConfig
        );
        break;
    }

    return fieldConfig;
  }

  generateTypeConfig(validationDescriptor) {
    switch (validationDescriptor.type) {
      case "string":
        return Joi.string();
      case "number":
        return Joi.number();
      case "date":
        return Joi.date();
      case "boolean":
        return Joi.boolean();
      case "array":
        return Joi.array();
      default:
        return Joi.string();
    }
  }

  generateRequiredConfig(validationDescriptor, fieldConfig) {
    if (validationDescriptor.flags.presence == "required")
      return fieldConfig.required();
    if (validationDescriptor.flags.presence == "optional")
      return fieldConfig.allow(["", null]);
    return fieldConfig.allow(["", null]);
  }

  generateStringConfig(validationDescriptor, fieldConfig) {
    if (validationDescriptor.rules) {
      for (const rule of validationDescriptor.rules) {
        if (rule.name === "uri") {
          return fieldConfig.uri();
        }
      }
    }
    return fieldConfig;
  }

  generateNumberConfig(validationDescriptor, fieldConfig) {
    return fieldConfig;
  }

  generateDateConfig(validationDescriptor, fieldConfig) {
    return fieldConfig;
  }

  generateBooleanConfig(validationDescriptor, fieldConfig) {
    return fieldConfig;
  }
}

module.exports = new Validator();
