class MetaGenerator {
  constructor() {}

  generate(validations) {
    // console.log('validations: ', validations)
    let result = {};
    result.fields = this._generateFields(validations);
    // console.log('meta: ', result)
    return result;
  }

  _generateFields(validations) {
    let result = [];
    let keys = Object.keys(validations);
    for (let key of keys) {
      const fieldValidation = validations[key];

      // if (fieldValidation.type == "array") continue;

      let field = {};
      field.key = key;
      field.type = "";
      switch (fieldValidation.type) {
        case "string":
          if (Array.isArray(fieldValidation.allow)) {
            field.type = "lookup";
            field.items = fieldValidation.allow;
            break;
          }
          field.type = "text";
          break;
        case "date":
          field.type = "date";
          break;
        case "array":
          field.type = "array";
          break;
      }
      field.label = fieldValidation.flags.description;
      field.required = fieldValidation.flags.presence == "required";
      field.rules = [];
      if (field.required) field.rules.push(value => !!value || "Required");
      result.push(field);
    }
    return result;
  }
}

module.exports = new MetaGenerator();
