const { callAPI } = require("ngt-frontend-core").apiOpsBff;

// const endpointsList = require('../dummy-data/id4all.json')

class ValidationRules {
  constructor() {
    this.resources = null;
  }

  async getValidations(id) {
    // let validations = null
    // let path = encodeURIComponent(id)

    // this.$store.state.validations = this.$store.state.validations || {}

    // if (this.$store.state.validations[path]){
    //     return this.$store.state.validations[path]
    // }
    // console.log(endpointsList.endpoints)
    // this.$store.state.validations[path] = await callAPI()
    // return this.$store.state.validations[path]

    if (this.resources == null) {
      const url = process.env.VUE_APP_IDP_API + "/_service/resources";
      // console.log(url)
      const result = await callAPI({ url, method: "GET" });
      this.resources = result.data;
    }
    // console.log(id)
    // console.log(this.resources.endpoints.sort((a, b) => a.id.localeCompare(b.id)))
    // console.log(this.resources);
    // console.log(id)

    // console.log(id)
    // console.log(this.resources.endpoints.sort((a, b) => a.id.localeCompare(b.id)))

    const endPoint = this.resources.endpoints.find(p => p.id == id).validations
      .payload.keys;

    // console.log(endPoint)

    return endPoint;
  }
}

module.exports = new ValidationRules();
