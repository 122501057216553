<template>
  <v-form ref="form" v-model="valid" lazy-validation class="pb-6" v-if="meta">
    <div v-for="(field, index) in meta.fields" :key="field.id">
      <v-text-field
        v-if="
          field.key !== 'ownedBy' &&
            field.key !== 'owner' &&
            field.key !== 'mfUser' &&
            field.key !== 'assetId' &&
            field.key !== 'driverId' &&
            field.key !== 'group' &&
            field.type === 'text'
        "
        v-model="data[field.key]"
        :rules="field.rules"
        :label="field.label"
        :autofocus="index == 0"
      />
      <v-text-field
        v-if="field.key !== 'ownedBy' && field.key === 'phone'"
        v-model="data[field.key]"
        :label="field.label"
        :autofocus="index == 0"
      />
      <!-- <v-select v-if="field.type == 'lookup' && field.key !== 'phone'" :items="field.items" v-model="data[field.key]" :rules="field.rules" :label="field.label" :autofocus="index == 0" /> -->
      <v-autocomplete
        v-if="field.type === 'lookup' && field.key !== 'phone'"
        :items="field.items"
        v-model="data[field.key]"
        :rules="field.rules"
        :label="field.label"
        :autofocus="index == 0"
      />
      <GsDatePicker
        v-if="field.type === 'date'"
        v-model="data[field.key]"
        :rules="field.rules"
        :label="field.label"
        :autofocus="index == 0"
      />
      <v-autocomplete
        v-if="field.key === 'ownedBy' || field.key === 'owner'"
        :items="customers"
        v-model="data[field.key]"
        :rules="field.rules"
        :label="$t('ownedBy')"
        :autofocus="index == 0"
        @change="ownedBy_changed"
      />
      <MFUsersDropdown
        v-if="field.key === 'mfUser'"
        v-model="data[field.key]"
        :rules="field.rules"
        :label="$t('mfUser')"
        :ownedBy="owners"
      />
      <AssetsDropdown
        ref="dropdown_assetId"
        v-if="field.key === 'assetId'"
        v-model="data[field.key]"
        :rules="field.rules"
        :label="$t('assetId')"
      />
      <DriversDropdown
        v-if="field.key === 'driverId'"
        v-model="data[field.key]"
        :rules="field.rules"
        :label="$t('driverId')"
      />
      <GroupsDropdown
        v-if="field.key === 'group'"
        v-model="data[field.key]"
        :rules="field.rules"
        :label="$t('groups')"
      />
    </div>
    <v-btn class="mr-2" small color="default" v-on:click="cancel_click()">
      <slot name="cancel">
        {{ $t("cancel") }}
      </slot>
    </v-btn>
    <v-btn small color="primary" v-on:click="save_click()">
      <slot name="save">
        {{ $t("save") }}
      </slot>
    </v-btn>
    <div v-if="errorMessage" style="color: red">{{ errorMessage }}</div>
  </v-form>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import validationRules from "@/managers/validationRules";
import validator from "@/managers/validator";
import metaGenerator from "@/managers/metaGenerator";
import GsDatePicker from "@/components/GsDatePicker";
import MFUsersDropdown from "@/components/FormComponents/MFUsersDropdown";
import AssetsDropdown from "@/components/FormComponents/AssetsDropdown";
import DriversDropdown from "@/components/FormComponents/DriversDropdown";
import GroupsDropdown from "@/components/FormComponents/GroupsDropdown";

export default {
  name: "GeneralForm",
  components: {
    GsDatePicker,
    MFUsersDropdown,
    AssetsDropdown,
    DriversDropdown,
    GroupsDropdown
  },
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    },
    cancel: {
      type: Function,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      validations: null,
      meta: null,
      data: null,
      valid: true,
      validationResult: null
    };
  },
  computed: {
    errorMessage() {
      if (!this.validationResult) return "";
      if (!this.validationResult.error) return "";
      if (!this.validationResult.error.details) return "";
      if (this.validationResult.error.details.length === 0) return "";
      return this.validationResult.error.details[0].message;
    },
    // pre-fetched data
    customers() {
      return this.$store.getters.customers();
    },
    owners() {
      if (!this.data["ownedBy"]) {
        return [];
      }
      return [this.data["ownedBy"]];
    }
  },
  created: async function() {
    this.validations = await validationRules.getValidations(
      "/" + this.config.path
    );
    this.meta = metaGenerator.generate(this.validations);
    // console.log(this.meta)
    this.config.initData = this.config.initData || {};
    this.data = {};
    for (let key of Object.keys(this.config.initData)) {
      this.data[key] = this.config.initData[key];
    }
  },
  methods: {
    ownedBy_changed(v) {
      // console.log("ownedBy_changed", v);
      this.$refs.dropdown_assetId[0].setOwnedBy(v);
    },
    getParsedPath: function() {
      this.config.map = this.config.map || {};
      let path = this.config.path.split(":")[0];
      for (let key of Object.keys(this.config.map)) {
        path = path.split("{" + key + "}").join(this.config.map[key]);
      }
      const url = this.config.url + "/" + path;
      const method = this.config.path.split(":")[1];
      return { url, method };
    },
    async cancel_click() {
      this.cancel();
    },
    constructSendData() {
      let sendData = {};
      for (let key of Object.keys(this.validations)) {
        //if (this.validations[key]?.flags?.presence === "required")
        sendData[key] = this.data[key];
      }
      return sendData;
    },
    async save_click() {
      let sendData = this.constructSendData();
      // console.log(sendData)
      this.validationResult = validator.validate(this.validations, sendData);
      if (this.validationResult.error) {
        console.log("validationResult: ", this.validationResult);
        return;
      }

      if (sendData.mfUser == null) {
        delete sendData.mfUser;
      }

      try {
        const { url, method } = this.getParsedPath();
        await callAPI({
          url: url,
          method: method,
          data: sendData
        });
      } catch (err) {
        console.log(err);
        this.validationResult = err;
      }
      this.save(this.data, sendData, this.validationResult);
    }
  }
};
</script>
